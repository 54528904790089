<template>
   <div class="corneaContactHome">
<!--     <h3>角膜接触镜</h3>-->
     <div>
       <ul>
         <li v-if="0" @click="go('/corneaContact/introduce')">
           <img src="@/assets/img/conrea/contact_introduction.png" alt="">
           <p>角膜接触镜介绍</p>
         </li>
         <li @click="go('/corneaContact/productDemonstration')">
           <img src="@/assets/img/conrea/contact_demo.png" alt="">
           <p>产品特性展示</p>
         </li>
         <li @click="go('/home/acceptsHome', 1)">
           <img src="@/assets/img/conrea/contact_inquiry.png" alt="">
           <p>问诊</p>
         </li>
         <li @click="go('/corneaContact/relatedTest')">
           <img src="@/assets/img/conrea/contact_check.png" alt="">
           <p>相关检查</p>
         </li>
         <li @click="go('/corneaContact/tryAssessmentHome', 2)">
           <img src="@/assets/img/conrea/contact_evaluation.png" alt="">
           <p>试戴与评估</p>
         </li>
       </ul>
       <ul class="pd-4">
         <li @click="go('/corneaContact/corneaOrder', 3)">
           <img src="@/assets/img/conrea/contact_order.png" alt="">
           <p>订单</p>
         </li>
         <li @click="go('/corneaContact/corneaReview')">
           <img src="@/assets/img/conrea/contact_recheck.png" alt="">
           <p>复查</p>
         </li>
         <li @click="go('/corneaContact/communication')">
           <img src="@/assets/img/conrea/contact_protocal.png" alt="">
           <p>患者沟通</p>
         </li>
         <li @click="go('/corneaContact/Iframe?type=2')">
           <img src="@/assets/img/conrea/contact_order.png" alt="">
           <p>角塑报告</p>
         </li>
       </ul>
     </div>

   </div>
</template>

<script>
export default {
  name: "corneaContactHome",
  data() {
    return {

    }
  },
  created() {
    this._api.expertClinic.getExSignature()
    .then(res => {
      if (res.GetResult) {
        this.$store.commit('expertClinic/upQmUrl', res.GetResult.UFPath)
      }
    })
  },
  methods: {
    go(url, type) {
      if (type == 1 && this.$store.state.users.user.Company.CompanyId === '42fd1c61-721c-11ed-bba1-7cd30adbe42c') {
        return  this.$router.push({path: '/corneaContact/inquiry'})
      }
      if (type == 2 && this.$store.state.users.user.Company.CompanyId === '42fd1c61-721c-11ed-bba1-7cd30adbe42c') {
        return  this.$router.push({path: '/corneaContact/tryJQ'})
      }
      if (type == 3 && this.$store.state.users.user.Company.CompanyId === '42fd1c61-721c-11ed-bba1-7cd30adbe42c') {
        return  this.$router.push({path: '/corneaContact/jqcorneaOrder'})
      }

      this.$router.push({path: url})
    }
  }
}
</script>

<style scoped lang="scss">
  .corneaContactHome {
    width: 100vw;
    height: 87vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h3 {padding: .5rem 0;}
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      padding: 0 .6rem;
      cursor: pointer;
      img {
        display: block;
        width: 150px;
        margin-bottom: .2rem;
        @media (max-width: 1000px) {
          width: 125px;
        }
      }
    }
  }
  .pd-4 {padding-top: 4vh}
</style>
